import { graphql } from "gatsby"
import React from "react"
import { Col, Media, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Markdown from "react-markdown"
import Moment from "react-moment"
import Footer from '../components/footer'
import Layout from "../components/layout"
import RecommandArticles1 from "../components/RecommandArticles1"
import RecommandArticles2 from "../components/RecommandArticles2"
import SEO from "../components/seo"
import './../assets/css/aritcle.css'
import BreadCrumb from './../components/breadcrumb'

export const query = graphql`  
  query ArticleQuery($slug: String!) {
    article: strapiArticles(slug: { eq: $slug }) {
      strapiId
      title
      content
      published_at
      description,
      recomment,
      articlesTag
    }
  }
`

const Article = (props) => {
  const article = props.data.article;

  const seo = {
    title: '物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台',//article.title,
    metaTitle: article?.title,
    metaDescription: article?.description,
    // shareImage: article?.image,
    article: true,
  };

  let content = article?.content.replace(/\/uploads\//g, (word) => {
    // return `${apiURL}/${word}`
    // /uploads/file => /strapi/uploads/file 保存在static中
    return `/strapi/${word}`;
  })

  // React.useEffect(() => {
  //   fetch(`${apiURL}/articles?_sort=updated_at:DESC`, {
  //     headers: { 'content-type': 'application/json' },
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         return res.json()
  //       }
  //     })
  //     .then(json => {
  //       console.log(json, article)
  //       let newJson
  //       if(article?.articlesTag == '答疑') {
  //         newJson = json?.length ? json.filter(itme=> itme.recomment == 'recomment' && itme.id != article.strapiId && itme.articlesTag == '答疑').splice(0,3) : []
  //       } else {
  //         newJson = json?.length ? json.filter(itme=> itme.recomment == 'recomment' && itme.id != article.strapiId && itme.articlesTag != '答疑').splice(0,3) : []
  //       }
  //       setDtData(newJson)
  //     })
  //     .catch(err => console.log(err))
  // }, [article?.articlesTag])

  const getBreadCrumb = (article) => {
    switch (article?.articlesTag) {
      case '软文':
        return <BreadCrumb title="行业动态" href="/articles" style={{ margin: 0 }} flag={true}></BreadCrumb>
      case '答疑':
        return <BreadCrumb title="答疑" href="/articles" style={{ margin: 0 }} flag={true}></BreadCrumb>
      default:
        return <BreadCrumb title="应用案例" href="/articles" style={{ margin: 0 }} flag={true}></BreadCrumb>
    }
  }

  return (
    <div className="help-page">
      <div className="home-bg" style={{
        height: '4.5rem',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 9999,
      }}>
        <Layout><SEO {...seo} /></Layout>
      </div>
      <Container className="content-box product-container" style={{ padding: '9.125rem 0 0 0' }}>
        <Row>
          <Col>
            {
              // article?.recomment == 'recomment' ?  :
              // <BreadCrumb title="应用案例" href="/articles" style={{ margin: 0 }} flag={true}></BreadCrumb>
              getBreadCrumb(article)
            }
            <div className="article-title">{article?.title}</div>
            <div className="article-desc">{article?.description}</div>
            <div className="article-img-box">
              <Media>
                <img width={36} height={36} className="mr-3" src={require('./../images/logo_home.png')} alt="" />
                <Media.Body>
                  <p className="time-art">admin</p>
                  <Moment className="time-art-p" format="YYYY年MM月DD">{article?.published_at}</Moment>
                </Media.Body>
              </Media>
            </div>
            <div className="article-box article-img-box">
              <Markdown source={content} escapeHtml={false} />
              {/* <div className="detail-box">
                <Link to="/price">
                  <Button className="getprice-btn btn btn-primary">点击获取更多优选案例产品包</Button>
                </Link>
              </div> */}
            </div>
          </Col>
          {
            article?.recomment == 'recomment' ? (
              article?.articlesTag == '答疑' ? (
                <RecommandArticles1 currentArticleSlug={article.slug}></RecommandArticles1>
              ) :
                <RecommandArticles2 currentArticleSlug={article.slug} currentArticleTag={article.articlesTag}></RecommandArticles2>
            ) : null
          }
        </Row>
      </Container>
      <Footer style={{ marginTop: '7.5rem' }}></Footer>
    </div>
  )
}

export default Article;
